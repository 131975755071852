import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getInvoices = async (params) => {
  return await request({
    url: "invoices/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};
