import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getTasks = async (project_id, params, cancelPrevious = true) => {
  return await request({
    url: `project/${project_id}/tasks/`,
    method: "GET",
    params: filterParams(params),
    cancelPrevious: cancelPrevious,
  });
};

export const getTask = async (project_id, id) => {
  return await request({
    url: `project/${project_id}/tasks/${id}/`,
    method: "GET"
  });
};

export const deleteTaskImage = async (project_id, task_id, id) => {
  return await request({
    url: `project/${project_id}/tasks/${task_id}/images/${id}/`,
    method: "DELETE"
  });
};

export const getTaskImages = async (project_id, task_id) => {
  return await request({
    url: `project/${project_id}/tasks/${task_id}/images/`,
    method: "GET"
  });
};

export const deleteTask = async (project_id, id) => {
  return await request({
    url: `project/${project_id}/tasks/${id}/`,
    method: "DELETE"
  });
};

export const editTask = async (project_id, id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "images")
      keyValue[1].forEach(_image => { if (!('id' in _image)) formD.append("images", _image) })
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `project/${project_id}/tasks/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const addTask = async (project_id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "images")
      keyValue[1].forEach(_image => { if (!('id' in _image)) formD.append("images", _image) })
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `project/${project_id}/tasks/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
