import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Row, Col, Alert, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
import { pick } from 'lodash';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { setLoader } from "utils/common";
import { loginUser, resetEmail } from "store/actions";

const Login = ({ ...props }) => {
  const { error, loading, email, success } = useSelector((state) => state.Login);
  const dispatch = useDispatch();

  const commonSchema = Yup.object({
    email: Yup.string().required("Please Enter Your Email"),
    password: Yup.string().required("Please Enter Your Password"),
  })
  const commonValues = {
    email: email || '',
    password: ''
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: commonValues,
    validationSchema: commonSchema,
    onSubmit: (values, actions) => {

      dispatch(loginUser(pick(
        { ...values },
        ['email', 'password']
      ), props.history, actions));
    }
  });

  const goBack = () => {
    dispatch(resetEmail())
  }

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {error ? <Alert color="danger">{error}</Alert> : null}
      {success && success ? (
        <Alert color="success">
          {success}
        </Alert>
      ) : null}

      <div className="mb-3">
        <Label className="form-label">Email</Label>
        <Input
          id="email"
          name="email"
          className="form-control"
          placeholder="Enter email"
          type="email"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.email || ""}
          invalid={
            validation.touched.email && validation.errors.email ? true : false
          }
        />
        {validation.touched.email && validation.errors.email ? (
          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
        ) : null}
      </div>

      <div className="mb-3">
        <Label className="form-label">Password</Label>
        <Input
          name="password"
          type="password"
          placeholder="Enter Password"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.password || ""}
          invalid={
            validation.touched.password && validation.errors.password ? true : false
          }
        />
        {validation.touched.password && validation.errors.password ? (
          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
        ) : null}
      </div>

      <Row className="py-2">
        <Col>
        </Col>

        <Col>
          <p className="mb-0">
            <Link to="/forgot-password" style={{ float: 'right' }} className="font-weight-medium text-primary">
              Forgot Password
            </Link>
          </p>
        </Col>
      </Row>

      <div className="mt-2">
        <button
          className="btn btn-primary d-block w-100"
          type="submit"
        >
          Login
        </button>
      </div>

      <div className="mt-1">
        <span
          to=""
          onClick={() => goBack()}
          className="btn btn-outline-primary d-block w-100">
          Back
        </span>
      </div>
    </Form>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
