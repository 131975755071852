import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import {
  getProject
} from "store/projects/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import ProjectDetail from "./projectDetail";
import ProjectMembers from './ProjectMembers'
import Tasks from "pages/Tasks/taskslist";
import TaskCountDetails from "./TaskCountDetails";
import Loader from "common/Loader";
//redux

const ProjectsOverview = props => {

  const {
    match: { params },
  } = props;

  useEffect(() => {
    dispatch(getProject(true, params.id, props.history))
  }, [])

  const dispatch = useDispatch();

  const { projectDetail, loading } = useSelector(state => ({
    projectDetail: state.projects.projectDetail,
    loading: state.projects.loading,
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Project Overview | Customer
          </title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              {/* Render Breadcrumbs */}
              <Breadcrumbs back={true} title="Projects" breadcrumbItem="Projects" />


              <Row>
                <Col lg="8">
                  <ProjectDetail loading={loading} project={projectDetail} />

                  <Tasks {...props} />
                </Col>
                <Col lg="4">
                  <ProjectMembers loading={loading} members={projectDetail ? projectDetail.users : []} />

                  <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
                    <CardHeader className="mb-2">
                      <CardTitle>Total Tasks</CardTitle>
                    </CardHeader>

                    {loading && <Loader />}
                    {projectDetail && <TaskCountDetails tasks={projectDetail.tasks || []} />}
                  </Card>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

ProjectsOverview.propTypes = {
  match: PropTypes.object,
};

export default withRouter(ProjectsOverview);
