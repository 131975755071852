import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'
import { newProjectModal, addProject } from 'store/projects/actions'
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.projects);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      project_name: '',
      project_description: '',
      project_address: '',
      project_start_date: '',
      project_end: ''
    },
    validationSchema: Yup.object({
      project_name: Yup.string().required("Please add Project Name"),
      project_description: Yup.string().required("Please add Project Description"),
      project_address: Yup.string().required("Please add Project Address"),
      project_start_date: Yup.string().required("Please add Project Start Date"),
      project_end: Yup.string().required("Please add Project End Date"),
    }),
    onSubmit: (values, actions) => {
      dispatch(addProject(values, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(newProjectModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newProjectModal())}>
          Add Project
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
