import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";

function index({ validation }) {

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Name</Label>
          <Input
            name="project_name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_name || ""}
            invalid={
              validation.touched.project_name && validation.errors.project_name ? true : false
            }
          />
          {validation.touched.project_name && validation.errors.project_name ? (
            <FormFeedback type="invalid">{validation.errors.project_name}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Description</Label>
          <Input
            name="project_description"
            type="textarea"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_description || ""}
            invalid={
              validation.touched.project_description && validation.errors.project_description ? true : false
            }
          />
          {validation.touched.project_description && validation.errors.project_description ? (
            <FormFeedback type="invalid">{validation.errors.project_description}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Address</Label>
          <Input
            name="project_address"
            type="textarea"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_address || ""}
            invalid={
              validation.touched.project_address && validation.errors.project_address ? true : false
            }
          />
          {validation.touched.project_address && validation.errors.project_address ? (
            <FormFeedback type="invalid">{validation.errors.project_address}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Start Date</Label>
          <Input
            name="project_start_date"
            type="date"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_start_date || ""}
            invalid={
              validation.touched.project_start_date && validation.errors.project_start_date ? true : false
            }
          />
          {validation.touched.project_start_date && validation.errors.project_start_date ? (
            <FormFeedback type="invalid">{validation.errors.project_start_date}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">End Date</Label>
          <Input
            name="project_end"
            type="date"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_end || ""}
            invalid={
              validation.touched.project_end && validation.errors.project_end ? true : false
            }
          />
          {validation.touched.project_end && validation.errors.project_end ? (
            <FormFeedback type="invalid">{validation.errors.project_end}</FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
