import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Alert, Form, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, useParams } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { setLoader } from "utils/common";
import { selectTenant } from "store/actions";
import AsyncSelect from 'react-select/async';
import { getTenants } from 'api/v3'
import { capitalize } from 'lodash'

const ResetPassword = ({ ...props }) => {
  const dispatch = useDispatch();

  const commonSchema = Yup.object({
    tenant: Yup.mixed().required("Please Select Company"),
  })
  const commonValues = {
    tenant: null
  }
  let { uidb64, token } = useParams();

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: commonValues,
    validationSchema: commonSchema,
    onSubmit: (values, actions) => {

      dispatch(selectTenant({
        tenant: values.tenant.schema_name,
        uidb64, token
      }, actions, props.history));
    }
  });

  const { error, loading, success } = useSelector((state) => state.Login);

  let loadTenants = () => {
    return getTenants({
      no_paginate: 1, uidb64,
      token
    })
      .then((results = []) => {
        return results
      })
      .catch(({ errors }) => {
        if ('non_field_errors' in errors)
          window.location.href = '/login'
      })
  }


  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        form.handleSubmit();
        return false;
      }}
    >
      {error ? <Alert color="danger">{error}</Alert> : null}
      {success && success ? (
        <Alert color="success">
          {success}
        </Alert>
      ) : null}

      <div className="mb-3">
        <Label className="form-label">Company</Label>
        <AsyncSelect
          defaultOptions
          placeholder="Select Company"
          required
          isSearchable={false}
          value={form.values.tenant}
          getOptionLabel={({ name }) => capitalize(name)}
          getOptionValue={({ id }) => id}
          loadOptions={loadTenants}
          onChange={(_tenant) => {
            form.setFieldValue('tenant', _tenant)
          }}

        />
        {form.touched.tenant && form.errors.tenant ? (
          <span className="text-danger font-size-11">{form.errors.tenant}</span>
        ) : null}
      </div>


      <div className="mt-4">
        <button
          className="btn btn-primary d-block w-100"
          type="submit"
        >
          Select
        </button>

        <div className="mt-1">
          <span
            to=""
            onClick={() => window.location.href = '/login'}
            className="btn btn-outline-primary d-block w-100">
            Back
          </span>
        </div>
      </div>
    </Form>
  );
};

export default withRouter(ResetPassword);

ResetPassword.propTypes = {
  history: PropTypes.object,
};
