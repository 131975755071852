import request from "../restClient";
import { filterData } from '../apiHelper'

export const login = async (data) => {
  return await request({ url: "login/", method: "POST", disableSubdomain: true, data: filterData(data) });
};

export const find = async (data) => {
  return await request({ url: "users/find/", method: "POST", disableSubdomain: true, data: filterData(data) });
};

export const requestPasswordChange = async (data) => {
  return await request({ url: "users/password_change/request/", method: "POST", disableSubdomain: true, data: filterData(data) });
};

export const verifyPasswordChange = async (data) => {
  let { uidb64, token, ..._formdata } = data
  return await request({ url: `users/password_change/verify_token/${uidb64}/${token}`, method: "POST", disableSubdomain: true, data: filterData(_formdata) });
};

export const passwordChange = async (data) => {
  let { uidb64, token, ..._formdata } = data
  return await request({ url: `users/password_change/${uidb64}/${token}`, method: "POST", disableSubdomain: true, data: filterData(_formdata) });
};


export const getTenants = async (data) => {
  let { uidb64, token } = data
  return await request({ url: `tenant/${uidb64}/${token}`, method: "GET", disableSubdomain: true });
};


export const selectTenant = async (data) => {
  let { uidb64, token, ..._data } = data
  return await request({ url: `tenant/${uidb64}/${token}`, method: "POST", disableSubdomain: true, data: _data });
};

