import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"
import Loader from "common/Loader"

const TaskMembers = ({ members, loading }) => {
  return (
    <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
      <CardBody className="p-0">
        <CardHeader>
          <CardTitle className="mb-2">Assignees</CardTitle>
        </CardHeader>
        {loading ?
          <Loader />
          : <div style={{ maxHeight: '360px', overflow: 'auto' }} className="table-responsive">
            {
              members.length > 0 ?
                <Table className="table align-middle table-nowrap">
                  <tbody>
                    {map(members, (_member, k) => {
                      let { profile: member } = _member

                      return (
                        <tr key={"_member_" + k} style={{ borderWidth: 0 }}>
                          <td style={{ width: "50px" }}>
                            {member.avatar !== "Null" ? (
                              <img
                                src={member.avatar}
                                className="rounded-circle avatar-xs"
                                alt=""
                              />
                            ) : (
                              <div className="avatar-xs">
                                <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                                  {member.first_name.charAt(0)}
                                </span>
                              </div>
                            )}
                          </td>
                          <td>
                            <h5 className="font-size-14 m-0">
                              <Link to="" className="text-dark">
                                {`${member.first_name} ${member.last_name}`}
                              </Link>
                            </h5>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                : <h6 className="p-3"> No Member Found</h6>
            }
          </div>
        }
      </CardBody>
    </Card>
  )
}

TaskMembers.propTypes = {
  team: PropTypes.array,
}

export default TaskMembers
