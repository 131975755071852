import PropTypes from "prop-types";
import React from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  UncontrolledAlert as Alert
} from "reactstrap";
import { Link } from "react-router-dom";

// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector } from "react-redux";

const Dashboard = props => {

  const { profile } = useSelector(state => ({
    profile: state.profiles.profile
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Customer</title>
        </MetaTags>
        <Container fluid>
          {
            profile && profile.profile.first_name == null
            && <Alert color="warning" role="alert">
              The profile is not setup yet, click{" "}
              <Link to="/profile" className="alert-link">
                here
              </Link>
              {" "} to setup your profile
            </Alert>
          }
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
