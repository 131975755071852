import React, { Fragment, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getInvoices as onGetInvoices
} from "store/invoices/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import moment from "moment";

const Invoices = props => {
  const dispatch = useDispatch();

  const { invoiceWithPagy, loading } = useSelector(state => ({
    invoiceWithPagy: state.invoices.invoiceWithPagy,
    loading: state.invoices.loading,
  }));
  let { results: invoices } = invoiceWithPagy

  //pagination customization
  const pageOptions = {
    sizePerPage: invoiceWithPagy.page_size,
    totalSize: invoiceWithPagy.total,
    page: invoiceWithPagy.page,
    custom: true,
  };

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(date, 'YYYY-MM-DD').format("DD MMM Y") : "N/A";
    return date1;
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Project",
      dataField: "project",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{`${row.project.project_name}`}</i></b>
    },
    {
      dataField: "project_start_date",
      text: "Start Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.project.project_start_date),
    },
    {
      dataField: "project_end",
      text: "End Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.project.project_end),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.status}`}</p>
        </>
      ),
    },
    {
      dataField: "print",
      text: "Print",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, invoice) => <Fragment>
        {
          invoice.pdf_file &&
          <a href={invoice.pdf_file} rel="noopener noreferrer" target={"_blank"} className="me-1">
            <i className="fas fa-print text-secondary font-size-18" />
          </a>
        }
      </Fragment>
    },
    {
      dataField: "timesheet_pdf",
      text: "Timesheets",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, invoice) => <Fragment>
        {
          invoice.timesheet_report?.pdf_file &&
          <a href={invoice.timesheet_report.pdf_file} rel="noopener noreferrer" target={"_blank"} className="me-1">
            <i className="fas fa-print text-secondary font-size-18" />
          </a>
        }
      </Fragment>
    }
  ];


  var node = useRef();

  function init() {
    dispatch(onGetInvoices(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetInvoices(true));
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Invoices</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Invoices" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={invoices}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={invoices || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
  onAddNewInvoice: PropTypes.func,
  onDeleteInvoice: PropTypes.func,
  onUpdateInvoice: PropTypes.func,
};

export default Invoices;
