import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR
} from "./actionTypes"

export const loginUser = (user, history, actions) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, actions },
  }
}

export const findUser = (user, actions) => {
  return {
    type: 'FIND_USER',
    payload: { user, actions },
  }
}

export const verifyPasswordChangeToken = (data, history) => {
  return {
    type: 'VERIFY_PASSWORD_CHANGE_TOKEN',
    payload: { data, history },
  }
}

export const requestPasswordChange = (user, actions, history) => {
  return {
    type: 'REQUEST_PASSWORD_CHANGE',
    payload: { user, actions, history },
  }
}

export const selectTenant = (company, actions, history) => {
  return {
    type: 'SELECT_TENANT',
    payload: { company, actions, history },
  }
}

export const resetPassword = (data, actions, history) => {
  return {
    type: 'RESET_PASSWORD',
    payload: { data, actions, history },
  }
}

export const resetEmail = () => {
  return {
    type: 'RESET_EMAIL',
  }
}

export const setEmail = email => {
  return {
    type: 'SET_EMAIL',
    payload: email
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const apiPassError = error => {
  return {
    type: 'API_PASS_ERROR',
    payload: error,
  }
}
