import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
} from "./actionTypes"

export const getInvoices = (loading = false, params = {}) => ({
  type: GET_INVOICES,
  payload: { loading, params }
})

export const getInvoicesSuccess = invoices => ({
  type: GET_INVOICES_SUCCESS,
  payload: invoices,
})

export const getInvoicesFail = error => ({
  type: GET_INVOICES_FAIL,
  payload: error,
})
