import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import profileSaga from './profiles/saga'
import invoicesSaga from './invoices/saga'
import tenantSaga from './tenants/saga'

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(profileSaga),
    fork(tenantSaga),
    fork(LayoutSaga),
    fork(invoicesSaga),
    fork(projectsSaga),
    fork(tasksSaga)
  ])
}
