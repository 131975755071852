import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getProjects = async (params) => {
  return await request({
    url: "project/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getProjectHours = async (id, params) => {
  return await request({
    url: `project/${id}/work_hours/`,
    method: "POST",
    data: {
      period_type: params.type,
      start_date: params.start_date,
      end_date: params.end_date
    }
  });
};

export const getProject = async (id) => {
  return await request({
    url: `project/${id}/`,
    method: "GET"
  });
};

export const getProjectPayroll = async (params) => {
  return await request({
    url: "project/",
    method: "GET",
    params: filterParams(params),
  });
};

export const getInvoicesCount = async () => {
  return await request({
    url: "invoices/count/",
    method: "GET"
  });
};

export const deleteProject = async (id) => {
  return await request({
    url: `project/${id}/`,
    method: "DELETE"
  });
};

export const editProject = async (id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `project/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const addProject = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `project/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
