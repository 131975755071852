import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";
import styles from './css/register.module.css'

import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Footer, Navbar } from 'components/AuthCommon'

import { withRouter, Link } from "react-router-dom";
import Preloader from "components/Common/Preloader";

import ResetPassword from "./ResetPassword";

const ResetPasswordControl = () => {

  return (
    <React.Fragment>
      <MetaTags>
        <title>Reset | Customer</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>

      <Preloader />
      <main>
        <Navbar />
        <Row>
          <Col className="d-flex" lg={6}>
            <div className={styles.coverImage}>
              <img src="/assets/img/reset.png" />
            </div>
          </Col>
          <Col lg={6}>
            <Container>
              <Row className="justify-content-center">
                <Col xs="12" lg="8" className="my-2 py-2">
                  <Card className="overflow-hidden">
                    <div className="p-4 text-center">
                      <h6>Welcome Back</h6>
                      <h4>Please Reset Password</h4>
                    </div>
                    <CardBody className="pt-0">
                      <div className="p-2">
                        <ResetPassword />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Footer />
      </main>
    </React.Fragment>
  );
};

export default withRouter(ResetPasswordControl);

ResetPasswordControl.propTypes = {
  history: PropTypes.object,
};
