import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import moment from "moment";
import Loader from "common/Loader";


const ProjectDetail = ({ project, loading }) => {
  return (
    <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
      <CardHeader>
        <CardTitle className="mb-2">Overview</CardTitle>
      </CardHeader>
      <CardBody>
        {
          loading ?
            <Loader />
            :
            <React.Fragment>
              {project && <React.Fragment>
                <Row>
                  <Col xs="4">
                    <h5 className="text-truncate font-size-14">{`Project Name`}</h5>
                    <h5 className="text-truncate font-size-14 mt-2">{`Address`}</h5>
                    <h5 className="text-truncate font-size-14 mt-2">{`Status`}</h5>
                  </Col>
                  <Col xs="8">
                    <h5 className="text-muted font-size-14">{`${project.project_name}`}</h5>
                    <h5 className="text-muted font-size-14 mt-2">{`${project.project_address || 'No Address added yet'}`}</h5>
                    <h5 className="text-muted font-size-14 mt-2"><b>{`${project.status}`}</b></h5>
                  </Col>
                </Row>

                <div className="d-flex mt-2">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="text-truncate font-size-14">{`Project Description`}</h5>
                    <p className="text-muted">
                      {project.project_description || 'No Description added yet'}
                    </p>
                  </div>
                </div>

                <Row className="task-dates mt-2">
                  <Col sm="4" xs="6">
                    <div>
                      <h5 className="font-size-14">
                        <i className="bx bx-calendar me-1" /> Start Date
                      </h5>
                      <p className="text-muted mb-0">{moment(project.project_start_date).format("DD MMM YYYY")}</p>
                    </div>
                  </Col>

                  <Col sm="4" xs="6">
                    <div>
                      <h5 className="font-size-14">
                        <i className="bx bx-calendar me-1" /> End Date
                      </h5>
                      <p className="text-muted mb-0">{moment(project.project_end).format("DD MMM YYYY")}</p>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
              }
            </React.Fragment>
        }
      </CardBody>
    </Card>
  );
};

ProjectDetail.propTypes = {
  project: PropTypes.object,
};

export default ProjectDetail;
