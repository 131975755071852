import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  passError: "",
  success: '',
  email: null,
  loading: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        error: "", success: "",
        loading: true,
      }
      break
    case 'VERIFY_PASSWORD_CHANGE_TOKEN':
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      }
      break
    case 'RESET_PASSWORD':
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      }
      break
    case 'FIND_USER':
      state = {
        ...state,
        error: "",
        passError: "",
        email: null,
        loading: true,
      }
      break
    case 'REQUEST_PASSWORD_CHANGE':
      state = {
        ...state,
        passError: "",
        error: "",
        email: null,
        loading: true,
      }
      break
    case 'SELECT_TENANT':
      state = {
        ...state,
        passError: "",
        error: "",
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loading: false,
      }
      break
    case 'RESET_EMAIL':
      state = {
        ...state, success: "",
        email: null,
        success: "",
        error: ""
      }
      break
    case 'SET_EMAIL':
      state = {
        ...state,
        email: action.payload,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, success: "", passError: "", error: action.payload, loading: false }
      break;
    case 'API_PASS_ERROR':
      state = { ...state, success: "", error: "", passError: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
