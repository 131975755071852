import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';

import {
  setTenant,
  onTenantComplete
} from "./actions";

import {
  getTenant as getTenantAPI,
  editTenant as editTenantAPI,
} from "api/v3";


function* fetchTenant() {
  try {
    const response = yield call(getTenantAPI);
    yield put(setTenant(response));

  } catch (error) {
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
  yield put(onTenantComplete());
}

function* editTenant({ payload: { tenant, actions } }) {
  try {
    const response = yield call(editTenantAPI, tenant)
    yield put(setTenant(response));
    toast.success("Company Updated!");

  } catch ({ errors }) {
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
  yield put(onTenantComplete());
}

function* tenantSaga() {
  yield takeEvery('GET_TENANT', fetchTenant);
  yield takeEvery('EDIT_TENANT', editTenant);
}

export default tenantSaga;
