import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_INVOICES
} from "./actionTypes";

import {
  getInvoicesSuccess,
  getInvoicesFail
} from "./actions";

import {
  getInvoices as getInvoicesAPI
} from "api/v3";


function* fetchInvoices({ payload: { params } }) {
  try {
    const response = yield call(getInvoicesAPI, params);
    yield put(getInvoicesSuccess(response));
  } catch (error) {
    yield put(getInvoicesFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices);
}

export default invoiceSaga;
