import { call, put, takeEvery, select } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_TASKS,
  DELETE_TASK,
  ADD_TASK,
  EDIT_TASK
} from "./actionTypes";

import {
  getTasks,
  getTasksSuccess,
  getTasksFail,
  deleteTaskModal,
  setTaskDetail,
  setTaskImages
} from "./actions";

import {
  getTasks as getTasksAPI,
  deleteTask as deleteTaskAPI,
  addTask as addTaskAPI,
  editTask as editTaskAPI,
  getTask as getTaskAPI,
  deleteTaskImage as deleteTaskImageAPI,
} from "api/v3";

function* fetchTask({ payload: { projectId, id, history } }) {
  try {
    const response = yield call(getTaskAPI, projectId, id);
    yield put(setTaskDetail(response));
  } catch (error) {
    yield put(getTasksFail("Object not found"))
    if (history) history.goBack()
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* fetchTasks({ payload: { params, projectId } }) {
  try {
    const response = yield call(getTasksAPI, projectId, params);
    yield put(getTasksSuccess(response));
  } catch (error) {
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addTask({ payload: { projectId, task, actions } }) {
  try {
    yield call(addTaskAPI, projectId, task)
    toast.success("Task Created!");
    yield put(getTasks(projectId, true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getTasksFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editTask({ payload: { projectId, id, task, actions } }) {
  try {
    yield call(editTaskAPI, projectId, id, task)
    toast.success("Task Updated!");
    yield put(getTasks(projectId, true));
  } catch ({ errors }) {
    yield put(getTasksFail("Updation failed!"))
    if (errors && actions) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deleteTask({ payload: { projectId, task } }) {
  try {
    yield call(deleteTaskAPI, projectId, task.id);
    put(deleteTaskModal())
    yield put(getTasks(projectId, true));
    toast.warn("Task Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* deleteTaskImage({ payload: { projectId, taskId, id } }) {
  const getImages = (state) => state.tasks.images
  const getTaskWithPagy = (state) => state.tasks.taskWithPagy

  let images = yield select(getImages)
  let filteredImages = images.filter(image => image.id != id)
  let taskWithPagy = yield select(getTaskWithPagy)
  try {
    yield call(deleteTaskImageAPI, projectId, taskId, id);
    yield put(setTaskImages(filteredImages))
    yield put(getTasksSuccess({ ...taskWithPagy, results: taskWithPagy.results.map(_task => _task.id == taskId ? { ..._task, images: filteredImages } : _task) }))
    toast.warn("Image Deleted!");
  } catch (error) {
    yield put(setTaskImages(images))
    toast.error("Something went wrong!");
  }
}

function* taskSaga() {
  yield takeEvery('GET_TASK', fetchTask);
  yield takeEvery(GET_TASKS, fetchTasks);
  yield takeEvery(DELETE_TASK, deleteTask);
  yield takeEvery('DELETE_TASK_IMAGE', deleteTaskImage);
  yield takeEvery(ADD_TASK, addTask);
  yield takeEvery(EDIT_TASK, editTask);
}

export default taskSaga;
