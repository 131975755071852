import React, { useEffect, useState, useRef, Fragment } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import { Menu, MenuItem } from '@szhsin/react-menu';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getProjects as onGetProjects,
  deleteProject as onDeleteProject,
  newProjectModal,
  deleteProjectModal,
  editProjectModal
} from "store/projects/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import moment from "moment";

const Projects = props => {
  const dispatch = useDispatch();
  const {
    match: { params },
  } = props;

  const { projectWithPagy, loading, deleteModal } = useSelector(state => ({
    projectWithPagy: state.projects.projectWithPagy,
    loading: state.projects.loading,
    deleteModal: state.projects.delete,
  }));
  let { results: projects } = projectWithPagy

  const [project, setProject] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: projectWithPagy.page_size,
    totalSize: projectWithPagy.total,
    page: projectWithPagy.page,
    custom: true,
  };

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(date, 'YYYY-MM-DD').format("DD MMM Y") : "N/A";
    return date1;
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "No",
      dataField: "index",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      dataField: "project_name",
      text: "Project Name",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.project_name}`}</p>
        </>
      ),
    },
    {
      dataField: "project_start_date",
      text: "Start Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.project_start_date),
    },
    {
      dataField: "project_end",
      text: "End Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.project_end),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.status}`}</p>
        </>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, project) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem
            onClick={() => props.history.push(`projects/${project.id}`)}
          >
            View Project
          </MenuItem>
          <MenuItem
            onClick={() => props.history.push(`projects/${project.id}/tasks`)}
          >
            View Tasks
          </MenuItem>
          {
            ['Processing', 'Open'].includes(project.status) &&
            <Fragment>
              <MenuItem
                onClick={() => handleEditProject(project)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => onClickDelete(project)}
              >
                Delete
              </MenuItem>
            </Fragment>
          }
        </Menu>
      ),
    },
  ];


  var node = useRef();

  //delete project

  const onClickDelete = (project) => {
    setProject(project);
    dispatch(deleteProjectModal())
  };

  const handleDeleteProject = () => {
    dispatch(onDeleteProject(project))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetProjects(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetProjects(true, { page, ...searchParams }));
  };

  const handleCreateProject = () => {
    dispatch(newProjectModal())
  };

  const handleEditProject = _project => {
    setProject(_project)
    dispatch(editProjectModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetProjects(false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProject}
        onCloseClick={() => dispatch(deleteProjectModal())}
      />

      <Create projectId={params.id} />
      <Edit projectId={params.id} project={project} />

      <div className="page-content">
        <MetaTags>
          <title>Projects</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Projects" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={projects}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={projects || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          searchText={searchParams.search}
                                          onSearch={text => handleSearch("search", text)} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleCreateProject}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        New Project
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Projects.propTypes = {
  projects: PropTypes.array,
  onGetProjects: PropTypes.func,
  onAddNewProject: PropTypes.func,
  onDeleteProject: PropTypes.func,
  onUpdateProject: PropTypes.func,
};

export default Projects;
