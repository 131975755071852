import React, { useEffect, useState, useRef, Fragment } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import { Menu, MenuItem } from '@szhsin/react-menu';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getTasks as onGetTasks,
  deleteTask as onDeleteTask,
  newTaskModal,
  deleteTaskModal,
  editTaskModal
} from "store/tasks/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import { getProject } from "store/actions";

const Tasks = props => {
  const dispatch = useDispatch();
  const {
    match: { params },
  } = props;

  const { taskWithPagy, loading, deleteModal, projectLoading, projectDetail } = useSelector(state => ({
    taskWithPagy: state.tasks.taskWithPagy,
    loading: state.tasks.loading,
    deleteModal: state.tasks.delete,
    projectDetail: state.projects.projectDetail,
    projectLoading: state.projects.loading,
  }));
  let { results: tasks } = taskWithPagy

  const [task, setTask] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: taskWithPagy.page_size,
    totalSize: taskWithPagy.total,
    page: taskWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Name",
      dataField: "task",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{row.task}</i></b>
    },
    {
      text: "Code",
      dataField: "code",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.code || 'N/A'}</>
    },
    {
      text: "Description",
      dataField: "description",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.description}</>
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.status}`}</p>
        </>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, task) => (
        <Fragment>
          {
            task.status == 'Processing' && projectDetail?.status == 'Processing' ?
              <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
                <MenuItem
                  onClick={() => handleEditTask(task)}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => onClickDelete(task)}
                >
                  Delete
                </MenuItem>
              </Menu>
              : null
          }
        </Fragment>
      ),
    },
  ];


  var node = useRef();

  //delete task

  const onClickDelete = (task) => {
    setTask(task);
    dispatch(deleteTaskModal())
  };

  const handleDeleteTask = () => {
    dispatch(onDeleteTask(params.id, task))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(getProject(true, params.id, props.history))
    dispatch(onGetTasks(params.id, true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading || projectLoading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetTasks(params.id, true, { page, ...searchParams }));
  };

  const handleCreateTask = () => {
    dispatch(newTaskModal())
  };

  const handleEditTask = _task => {
    setTask(_task)
    dispatch(editTaskModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetTasks(params.id, false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => dispatch(deleteTaskModal())}
      />

      <Create projectId={params.id} />
      <Edit projectId={params.id} task={task} />

      <div className="page-content">
        <MetaTags>
          <title>Tasks</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Tasks" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={tasks}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={tasks || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          searchText={searchParams.search}
                                          onSearch={text => handleSearch("search", text)} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        disabled={projectDetail?.status != 'Processing'}
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleCreateTask}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        New Task
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Tasks.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
  onAddNewTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
};

export default Tasks;
