import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import Dropzone from 'react-dropzone-uploader';
import './style.scss'
import ImageContainer from "./ImageContainer";

function index({ validation, handleDeleteImage, edit = false }) {
  let [images, setImages] = React.useState([])

  React.useEffect(() => {
    validation.setFieldValue("images", images.map(_img => _img.file))
  }, [images])

  const handleChangeStatus = ({ meta, file, xhr, ...rest }, status) => {

    const fileRemove = rest.remove;
    if (status === "removed") {
      setImages(fids => fids.filter(item => item.name !== file.name));
    }
    if (status === "done") {
      setImages(fids => [...fids, {
        file,
        name: file.name,
        remove: fileRemove
      }]);


    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row form>
        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">Task Name</Label>
            <Input
              name="task"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.task || ""}
              invalid={
                validation.touched.task && validation.errors.task ? true : false
              }
            />
            {validation.touched.task && validation.errors.task ? (
              <FormFeedback type="invalid">{validation.errors.task}</FormFeedback>
            ) : null}
          </div>
          
          <div className="mb-3">
            <Label className="form-label">Code</Label>
            <Input
              name="code"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.code || ""}
              invalid={
                validation.touched.code && validation.errors.code ? true : false
              }
            />
            {validation.touched.code && validation.errors.code ? (
              <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
            ) : null}
          </div>
          

          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <Input
              name="description"
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={
                validation.touched.description && validation.errors.description ? true : false
              }
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <div className="mb-3">
          <Label className="form-label">Image</Label>
          <Dropzone
            inputContent={<span key="some_key" className="h5 mb-0">Click here or drag images</span>}
            onChangeStatus={handleChangeStatus}
            accept="image/*"
          />
        </div>

        {
          edit &&
          <div className="mb-3">
            <ImageContainer handleDeleteImage={handleDeleteImage} />
          </div>
        }
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
