import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//invoices
import invoices from "./invoices/reducer"

//profiles
import profiles from "./profiles/reducer"

//tenants
import tenants from "./tenants/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  profiles,
  tenants,
  projects,
  invoices,
  tasks
})

export default rootReducer
