import {
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_INVOICES
} from "./actionTypes";

const INIT_STATE = {
  invoiceWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  loading: false
};

const invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        loading: action.payload.loading
      };

    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceWithPagy: action.payload,
      };

    case GET_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default invoices;
