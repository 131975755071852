export function filterParams(params = {}) {
  const payload = {};

  Object.keys(params).forEach((key) => {
    payload[key] = typeof params[key] === 'string' && !params[key] ? null : params[key];
  });

  return payload;
}


export function filterData(data = {}) {
  const payload = {};

  Object.entries(data).forEach((keyValue) => {
    if (keyValue[1])
      payload[keyValue[0]] = keyValue[1]
  })

  return payload
}
