import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_PROJECTS,
  DELETE_PROJECT,
  ADD_PROJECT,
  EDIT_PROJECT
} from "./actionTypes";

import {
  getProjectsSuccess,
  getProjects,
  getProjectsFail,
  deleteProjectModal,
  setProjectDetail,
  setProjectHours
} from "./actions";

import {
  getProjects as getProjectsAPI,
  getProject as getProjectAPI,
  deleteProject as deleteProjectAPI,
  addProject as addProjectAPI,
  editProject as editProjectAPI,
  getProjectHours as getProjectHoursAPI
} from "api/v3";


function* fetchProjects({ payload: { params } }) {
  try {
    const response = yield call(getProjectsAPI, { ...params, page_size: 30 });
    yield put(getProjectsSuccess(response));
  } catch (error) {
    yield put(getProjectsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* fetchProject({ payload: { id, history } }) {
  try {
    const response = yield call(getProjectAPI, id);
    yield put(setProjectDetail(response));
  } catch (error) {
    yield put(getProjectsFail("Object not found"))
    if (history) history.goBack()
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* fetchProjectHours({ payload: { id, params } }) {
  try {
    const response = yield call(getProjectHoursAPI, id, params);
    yield put(setProjectHours(response));
  } catch (error) {
    yield put(getProjectsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addProject({ payload: { project, actions } }) {
  try {
    yield call(addProjectAPI, project)
    toast.success("Project Created!");
    yield put(getProjects(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getProjectsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editProject({ payload: { id, project, actions } }) {
  try {
    yield call(editProjectAPI, id, project)
    toast.success("Project Updated!");
    yield put(getProjects(true));

  } catch ({ errors }) {
    yield put(getProjectsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deleteProject({ payload: { project } }) {
  try {
    yield call(deleteProjectAPI, project.id);
    put(deleteProjectModal())
    yield put(getProjects(true));
    toast.warn("Project Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* projectSaga() {
  yield takeEvery('GET_PROJECT', fetchProject);
  yield takeEvery(GET_PROJECTS, fetchProjects);
  yield takeEvery(DELETE_PROJECT, deleteProject);
  yield takeEvery('GET_PROJECTS_HOURS', fetchProjectHours);
  yield takeEvery(ADD_PROJECT, addProject);
  yield takeEvery(EDIT_PROJECT, editProject);
}

export default projectSaga;
