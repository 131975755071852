import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Settings/Profile"

// //Projects
import ProjectsList from "../pages/Projects/index"
import Invoices from "../pages/Invoices/index"
import TaskList from "../pages/Tasks/index"
import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"

// Authentication related pages
import Login from "../pages/Authentication/LoginControl"
import ResetPassword from "../pages/Authentication/ResetPasswordControl"
import CompanyControl from "../pages/Authentication/CompanyControl"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Password from "pages/Settings/Password"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/settings/profile", component: UserProfile },
  { path: "/settings/password", component: Password },


  //Projects
  { path: "/projects", component: ProjectsList },
  { path: "/invoices", component: Invoices },
  { path: "/projects/:id/tasks", exact: true, component: TaskList },
  { path: "/projects/:id", exact: true, component: ProjectsOverview },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/reset-password/:uidb64/:token", component: ResetPassword },
  { path: "/login/company/:uidb64/:token", component: CompanyControl },
  { path: "/forgot-password", component: ForgetPwd }
]

export { authProtectedRoutes, publicRoutes }
