import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editTaskModal, editTask, deleteTaskImage, setTaskImages } from 'store/tasks/actions'

function index({ projectId, task: target, searchParams }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.tasks);
  let [task, setTask] = useState()

  useEffect(() => {
    if (modal) {
      setTask(target)
      dispatch(setTaskImages(target.images || []))
    }
  }, [modal])

  function handleDeleteImage(id) {
    dispatch(deleteTaskImage(projectId, target.id, id))
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      task: (task && task.task) || '',
      code: (task && task.code) || '',
      description: (task && task.description) || '',
      images: (task && task.images) || [],
    },
    validationSchema: Yup.object({
      task: Yup.string().required("Please Enter Task Name"),
      description: Yup.string().required("Please Enter Task Description"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editTask(projectId, task.id, values, actions, searchParams))
    },
  });



  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editTaskModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editTaskModal())}>
          Edit Task
        </ModalHeader>
        <ModalBody>
          <Form
            edit={true}
            handleDeleteImage={handleDeleteImage}
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
